<template>
  <div class="page-content">
    <div class="container">
      <div class="ce company-employee red-theme">
        <div class="row">
          <div class="col-12">
            <h2 class="ce-title">{{ companyInfo.name }}</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-6">
            <div class="ce-box-bb">
              <div class="ic-ell ce-ell">
                <div class="ic-ell__icon">
                  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                </div>
                <div class="ce__text">
                  <strong>Страна:</strong>
                  {{ normalizeCompanyInfo.country }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="ce-box-bb">
              <div class="ic-ell ce-ell">
                <div class="ic-ell__icon">
                  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                </div>
                <div class="ce__text">
                  <strong>Регион:</strong>
                  {{ normalizeCompanyInfo.region }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="ce-box-bb">
              <div class="ic-ell ce-ell">
                <div class="ic-ell__icon">
                  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                </div>
                <div class="ce__text">
                  <strong>Организационно-правовая форма:</strong>
                  {{ normalizeCompanyInfo.legalFormName }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="ce-box-bb">
              <div class="ic-ell ce-ell">
                <div class="ic-ell__icon">
                  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                </div>
                <div class="ce__text">
                  <strong>Количество сотрудников:</strong>
                  {{ companyInfo.staff_num }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="ce-box-bb">
              <div class="ic-ell ce-ell">
                <div class="ic-ell__icon">
                  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                </div>
                <div class="ce__text">
                  <strong>ИНН:</strong>
                  {{ normalizeCompanyInfo.inn }}
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <div class="ce-box-bb">
              <div class="ic-ell ce-ell">
                <div class="ic-ell__icon">
                  <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ic-ell__svg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.021 6.41L8.785.646l.736.762-6.5 6.5L0 4.887l.762-.762 2.26 2.285z" fill="#0C72D6"/></svg>
                </div>
                <div class="ce__text">
                  <strong>ОГРН/ОГРНИП:</strong>
                  {{ normalizeCompanyInfo.ogrn }}
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="row">
          <div class="col-12">
            <h3 class="ce-title">
              Документы компании
            </h3>
          </div>
        </div>

        <form class="ce-form">
          <div class="row">
            <div class="col-12 col-md-6">
              <div class="ce-form__upload">
                <div class="ce-form__wrapp">
                  <img src="@img/temp/avatar-1.jpg" alt="" class="ce-form__pic">
                  <div class="ce-form__check">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ce-form__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.111 23.247c.762 0 1.359-.305 1.778-.927L22.772 3.988c.304-.457.431-.876.431-1.27 0-1.04-.762-1.79-1.828-1.79-.736 0-1.18.255-1.625.966L9.06 18.804l-5.458-6.881c-.432-.52-.876-.762-1.511-.762-1.08 0-1.854.762-1.854 1.816 0 .457.153.888.546 1.345l6.564 8.062c.495.596 1.04.863 1.764.863z"/></svg>
                  </div>
                </div>
                <p class="ce-form__text">Скан ИНН</p>
              </div>
            </div>

            <div class="col-12 col-md-6">
              <div class="ce-form__upload">
                <div class="ce-form__wrapp">
                  <img src="@img/temp/avatar-1.jpg" alt="" class="ce-form__pic">
                  <div class="ce-form__check">
                    <svg viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'ce-form__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.111 23.247c.762 0 1.359-.305 1.778-.927L22.772 3.988c.304-.457.431-.876.431-1.27 0-1.04-.762-1.79-1.828-1.79-.736 0-1.18.255-1.625.966L9.06 18.804l-5.458-6.881c-.432-.52-.876-.762-1.511-.762-1.08 0-1.854.762-1.854 1.816 0 .457.153.888.546 1.345l6.564 8.062c.495.596 1.04.863 1.764.863z"/></svg>
                  </div>
                </div>
                <p class="ce-form__text">Скан ОГРН/ОГРНИП</p>
              </div>
            </div>
          </div>
        </form>

<!--        <div class="row">-->
<!--          <div class="col-12">-->
<!--            <h3 class="ce-title">Вакансии</h3>-->
<!--          </div>-->
<!--        </div>-->

<!--        <div class="ce-services mb-4">-->
<!--          <div class="row">-->
<!--            <div class="col-12 col-md-6">-->
<!--              <span class="ce__sub ce__sub-title">Медицинская помощь:</span>-->
<!--              <span class="ce__sub ce__sub-desc">Услуги медсестры</span>-->
<!--            </div>-->
<!--            <div class="col-12 col-md-6">-->
<!--              <span class="ce__sub ce__sub-title">Медицинская помощь:</span>-->
<!--              <span class="ce__sub ce__sub-desc">Услуги медсестры</span>-->
<!--            </div>-->
<!--            <div class="col-12 col-md-6">-->
<!--              <span class="ce__sub ce__sub-title">Медицинская помощь:</span>-->
<!--              <span class="ce__sub ce__sub-desc">Услуги медсестры</span>-->
<!--            </div>-->
<!--            <div class="col-12 col-md-6">-->
<!--              <span class="ce__sub ce__sub-title">Медицинская помощь:</span>-->
<!--              <span class="ce__sub ce__sub-desc">Услуги медсестры</span>-->
<!--            </div>-->
<!--            <div class="col-12 col-md-6">-->
<!--              <span class="ce__sub ce__sub-title">Медицинская помощь:</span>-->
<!--              <span class="ce__sub ce__sub-desc">Услуги медсестры</span>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->

        <div class="row justify-content-center">
          <div class="col-md-10">
            <div class="row">
              <template v-if="!isCompanyEmployee">
                <div class="col-12 col-md-3 mb-2">
                  <router-link
                    :to="{ name: $routeName.ASSISTANCE_PROVIDE_LIST_INVITATIONS }"
                    class="btn btn--prime btn--border btn--fs"
                  >Назад</router-link>
                </div>
                <div class="col-12 col-md-3 mb-2">
                  <button
                    @click="rejectInvite"
                    class="btn btn--prime btn--border btn--fs"
                  >Отклонить</button>
                </div>

                <div class="col-12 col-md-6 mb-2">
                  <button
                    @click="acceptInvite"
                    class="btn btn--prime btn--fs"
                  >Откликнуться на вакансию</button>
                </div>
              </template>
              <template v-else>
                <div class="col-12 col-md-6 ml-auto mr-auto mb-2">
                  <router-link
                    :to="{ name: $routeName.ASSISTANCE_PROVIDE_APPLICATIONS }"
                  >
                    <button class="btn btn--prime btn--fs">Вернуться к заявкам</button>
                  </router-link>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: "CompanyDataEmployee",
  components: {

  },
  data() {
    return {
      companyInfo: {},
      regions: [],
      inviteId: this.$route.params.inviteId,
    };
  },

  computed: {
    ...mapState({
      // regions: state => state.enums.regions,
      countries: state => state.enums.countries,
      user: state => state.user.user
    }),
    isCompanyEmployee() {
      return this.user?.my_company;
    },
    normalizeCompanyInfo() {
      const { name, legal_form, docs, region } = this.companyInfo;
      return {
        name,
        region: this.regions.find(item => item.id === region)?.name,
        country: this.countries.find(country => country.id === legal_form?.country)?.name,
        legalFormName:  legal_form?.name,
        inn: docs?.inn,
        ogrn: docs?.ogrn,
      };
    }
  },

  created() {
    const inviteId = this.$route.params.inviteId;
    // TODO: Move to navigation guards
    this.getInviteInfo(inviteId)
      .then(({ from_company, status }) => {
        const { REJECTED, REJECTED_COMPANY } = this.$ENUM.USER_INVITE_STATUSES;
        if ([REJECTED_COMPANY.KEY, REJECTED.KEY].includes(status)) {
          this.$router.push({ name: this.$routeName.ASSISTANCE_PROVIDE_LIST_INVITATIONS });
        }
        this.companyInfo = from_company;
        const { legal_form } = from_company;
        const countryId = legal_form?.country;
        if (countryId) {
          RequestManager.Enums.getRegions(countryId)
            .then((result) => this.regions = result);
        }
      })
      .catch(err => {
        this.$router.push({ name: this.$routeName.NOT_FOUND });
      });
  },

  methods: {
    ...mapActions('invite', ['getInviteInfo', 'setInviteStatus']),
    ...mapActions('user', ['getUser']),
    rejectInvite() {
      this.$modal.show('Confirm', {
        title: 'Вы уверены, что хотите отклонить приглашение?'
      })
        .then((res) => {
          if (!res) return;
            this.setInviteStatus({
              id: this.inviteId,
              status: this.$ENUM.USER_INVITE_STATUSES.REJECTED.KEY
            })
              .then(() => {
                this.$router.push({ name: this.$routeName.ASSISTANCE_PROVIDE_LIST_INVITATIONS });
              });
        });
    },
    acceptInvite() {
      this.$modal.show('Confirm', {
        title: 'Вы уверены, что хотите принять приглашение?'
      })
        .then((res) => {
          if (!res) return;
          this.setInviteStatus({
            id: this.inviteId,
            status: this.$ENUM.USER_INVITE_STATUSES.JOINED.KEY
          })
            .then( async () => {
              //this.$router.push({ name: this.$routeName.ASSISTANCE_PROVIDE_LIST_INVITATIONS });
              await this.getUser();
              this.$modal.show('Accept', {
                title: 'Поздравляем! Вы стали сотрудником компании.'
              });
            });
        });
    }
  }
};
</script>

<style lang="scss" scoped>

.company-employee {
  background: #FFFFFF;
  border: 1px solid rgba(118, 118, 128, 0.12);
  border-radius: 10px;
  padding: 47px 26px;

  .ce-box-bb {
    padding: 8px 0 15px 0;
    border-bottom: 1px solid rgba(163, 163, 163, 0.3);
  }

  .ce__bold-text {
    font-weight: 600;
    font-size: 15px;
    color: #020202;
  }

  .ce__text {
    font-weight: 400;
    font-size: 15px;
    color: #020202;
  }

  .ce-ell {
    display: flex;
    align-items: baseline;
  }

  .ce-title {
    color: #000000;
    font-weight: 600;
    font-size: 19px;
    margin-top: 20px;
    margin-bottom: 27px;
  }

  .ce-services {
    background: #FFFFFF;
    border: 1px solid rgba(118, 118, 128, 0.2);
    overflow: hidden;
    padding: 20px 15px;
    border-radius: 8px;
  }

  .ce__sub {
    font-size: 17px;
    margin-right: 5px;
    margin-bottom: 10px;
    display: inline-block;
  }

  .ce__sub-title {
    color: rgba(0, 0, 0, 0.4);
  }

  .ce__sub-desc {
    color: #000000;
  }

  .ce-form__upload {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
  }

  .ce-form__wrapp {
    position: relative;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    overflow: hidden;
    border-radius: 50%;
    margin-right: 15px;
  }

  .ce-form__check {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }

  .ce-form__pic {
    width: 60px;
    height: 60px;
    object-fit: cover;
  }

  .ce-form__icon {
    fill: #35C759;
    width: 30px;
    height: 30px;
  }

  .ef-form__text {
    font-size: 17px;
  }

  .ce-invited {
    font-weight: 600;
    font-size: 19px;
    @media(max-width: 768px) {
      font-size: 16px;
    }
  }

  .ic-ell__icon--red {
    border-color: var(--color-prime);
    background: var(--color-prime);
  }

  .ic-ell__svg-white {
    width: 10px;
    height: 8px;
    fill: #FFFFFF;
  }

}
</style>
